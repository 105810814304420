'use client';

import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Link from 'next/link';

import { CUSTOMIZABLE_CARD_ID } from '@/app/(Shop)/cards/constant';
import ImportButton from '@/components/ImportButton';
import { Dialog, DialogContent, DialogTrigger } from '@/components/Modal';
import {
  CUSTOM_WALL_ART_ID,
  CUSTOM_WALL_ART_LIST,
  CustomWallartData,
} from '@/components/wallArts/constants';
import { PRINT_ID, RETRO_ID } from '@/constants';
import { FullscreenContextActions } from '@/context/FullScreenContext';
import togglePreparingLoaderAndDonePopup from '@/context/FullScreenContext/popups/actions/togglePreparingLoaderAndDonePopup';
import { UploaderContextAction } from '@/context/Uploader';
import { sendLog } from '@/services/logs';
import sendGTMLog from '@/services/logs/sendGTMLog';
import { GTMLogEvent } from '@/services/logs/types';
import removeCartItem from '@/stores/Cart/actions/image/removeCartItem';
import setCartItemImageBlobUrl from '@/stores/Cart/actions/image/setCartItemImageBlobUrl';
import setCartRetroPrint from '@/stores/Cart/actions/image/setCartPolaImageBlobUrl';
import updateCartItemImageUrl from '@/stores/Cart/actions/image/updateCartItemImageUrl';
import updateRetroPrintImageUrl from '@/stores/Cart/actions/image/updateRetroPrintImageUrl';
import addToCartItem from '@/stores/Cart/actions/job/addToCartItem';
import addToCartRetroPrint from '@/stores/Cart/actions/job/addToCartRetroPrint';
import {
  getRetroTemplateAssetLayer,
  retroPrintTemplate,
} from '@/stores/Cart/actions/job/retroPrintTemplate';
import { resetSquareConversionSuggestedIds } from '@/stores/Cart/CartStore';
import { Group, Product, ProductGroup } from '@/types/products';
import getDefaultWallArtProduct from '@/utils/products/getDefaultWallArtProduct';
import productHasDecreasingPrices from '@/utils/products/productHasDecreasingPrices';
import getAssetLayerRatio from '@/utils/template/getAssetLayerRatio';

import PricesInformation from './PricesInformation';

import styles from './styles.module.scss';

interface Props {
  product: Product;
  group: Group;
  productGroup: ProductGroup;
  index: number;
  productsList?: ProductGroup[];
  products: Product[];
  groups: Group[];
}

function ProductWrapper({
  product,
  group,
  productGroup,
  index,
  productsList,
  children,
  products,
  groups,
}: PropsWithChildren<Props>) {
  const { addImagesToUploader } = useContext(UploaderContextAction);
  const { setPopupChildren } = useContext(FullscreenContextActions);

  const [data, setData] = useState<CustomWallartData>(
    CUSTOM_WALL_ART_LIST[0] as CustomWallartData,
  );

  const defaultPoster = useRef('');

  const hasDecreasingPrices = productHasDecreasingPrices(product);

  useEffect(() => {
    if (group.id === CUSTOM_WALL_ART_ID) {
      CUSTOM_WALL_ART_LIST.forEach((customWallArt) => {
        if (productGroup.id.includes(customWallArt.id)) {
          setData(customWallArt);
        }
      });
    }
  }, [group.id, productGroup.id]);

  useEffect(() => {
    if (!!products.length && !!groups.length) {
      const defaultWallArt = getDefaultWallArtProduct(products, groups);
      if (defaultWallArt) {
        defaultPoster.current = defaultWallArt?.id;
      }
    }
  }, [groups, products]);

  return (
    <div className={styles.containerUploadButton}>
      {/* WALL ARTS */}
      {group.id === CUSTOM_WALL_ART_ID ? (
        <Link
          href={data.target}
          onClick={() => {
            sendLog('ui_catalog_itemTapped', {
              itemId: product.id,
              target: data.target.replace('/', ''),
              group: productsList?.map((nextProductGroup) =>
                nextProductGroup.id.replace(defaultPoster.current, ''),
              ),
              index,
            });
          }}
        >
          {children}
        </Link>
      ) : null}
      {/* CARDS */}
      {group.id === CUSTOMIZABLE_CARD_ID ? (
        <Link
          href={
            productGroup.collectionId
              ? `/cards/collection/${productGroup.collectionId}?galleryLayout=twoColumns `
              : '/cards'
          }
          onClick={() => {
            sendLog('ui_catalog_itemTapped', {
              itemId: product.id,
              index,
              collectionId: productGroup.collectionId,
            });
          }}
        >
          {children}
        </Link>
      ) : null}
      {/* Retro print have a different logic instead of others products (prints / custom card) and need a hybrid behaviour.
				Import image like print but join template and edit retro like custom card
			*/}
      {productGroup.id === RETRO_ID ? (
        <ImportButton
          onClick={() => {
            sendLog('ui_catalog_itemTapped', {
              itemId: product.id,
            });
          }}
          callback={(images) => {
            sendLog('ui_catalog_itemPhotosSelected', {
              itemId: product.id,
              count: images.length,
            });
            sendGTMLog({
              event: GTMLogEvent.selectItem,
              value: {
                index,
                group,
                product,
              },
            });

            resetSquareConversionSuggestedIds();
            const template = retroPrintTemplate;
            const assetLayer = getRetroTemplateAssetLayer(template);
            const ratio = getAssetLayerRatio(assetLayer, template);

            addImagesToUploader({
              images,
              dpi: product.specs.dpi?.maxDpi,
              ratio,
              jobStatusChangedCallbacks: [
                addToCartRetroPrint({
                  product,
                  onAdded: (cartItem) => {
                    sendGTMLog({
                      event: GTMLogEvent.addToCart,
                      value: {
                        products,
                        groups,
                        cartItem,
                      },
                    });
                  },
                }),
                togglePreparingLoaderAndDonePopup({
                  setPopupChildren,
                }),
              ],
              imageStatusChangedCallbacks: [
                setCartRetroPrint(),
                updateRetroPrintImageUrl(),
                removeCartItem(),
              ],
            });
          }}
        >
          {children}
        </ImportButton>
      ) : null}

      {/* OTHERS */}
      {group.id !== CUSTOMIZABLE_CARD_ID &&
      group.id !== CUSTOM_WALL_ART_ID &&
      productGroup.id !== RETRO_ID ? (
        <ImportButton
          onClick={() => {
            sendLog('ui_catalog_itemTapped', {
              itemId: product.id,
            });
          }}
          callback={(images) => {
            sendLog('ui_catalog_itemPhotosSelected', {
              itemId: product.id,
              count: images.length,
            });
            sendGTMLog({
              event: GTMLogEvent.selectItem,
              value: {
                index,
                group,
                product,
              },
            });

            resetSquareConversionSuggestedIds();

            addImagesToUploader({
              images,
              dpi: product.specs.dpi?.maxDpi,
              ratio: product.specs.size,
              jobStatusChangedCallbacks: [
                addToCartItem({
                  product,
                  metadata: { productType: PRINT_ID },
                  onAdded: (cartItem) => {
                    sendGTMLog({
                      event: GTMLogEvent.addToCart,
                      value: {
                        products,
                        groups,
                        cartItem,
                      },
                    });
                  },
                }),
                togglePreparingLoaderAndDonePopup({
                  setPopupChildren,
                }),
              ],
              imageStatusChangedCallbacks: [
                setCartItemImageBlobUrl(),
                updateCartItemImageUrl(),
                removeCartItem(),
              ],
            });
          }}
        >
          {children}
        </ImportButton>
      ) : null}
      {hasDecreasingPrices && (
        <Dialog>
          <DialogTrigger
            className={styles.moreInfosClick}
            aria-label="Modal for Prices informations"
          />
          <DialogContent placement="phoneBottom">
            <PricesInformation product={product} />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default ProductWrapper;
